@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .sidebarButton {
    @apply flex items-center justify-between px-4 py-4 border;
  }
  .sidebarButtonDiv {
    @apply flex space-x-4 lg:space-x-8;
  }
  .sidebarSubButtonContainer {
    @apply flex flex-col bg-slate-100 lg:px-4 xl:px-6 2xl:px-8 py-8 space-y-4;
  }
  .sidebarSubButton {
    @apply flex space-x-4 items-center;
  }
  .mobileSidebarButton {
    @apply flex items-center justify-between px-8 py-8 border;
  }
  .mobileSidebarButtonDiv {
    @apply flex space-x-8;
  }
  .mobileSubButtonContainer {
    @apply flex flex-col bg-slate-100 px-8 py-8 space-y-4;
  }
  .mobileSubButton {
    @apply flex space-x-8 items-center;
  }
}
